import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

function RootIndex({ pageContext }) {
  return <div>404</div>
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default RootIndex
